/*
      ##    #######   ##     ##  ########   ########   ##    ##   
      ##   ##     ##  ##     ##  ##         ##     ##   ##  ##    
      ##   ##     ##  ##     ##  ##         ##     ##    ####     
      ##   ##     ##  ##     ##  ######     ########      ##      
##    ##   ##  ## ##  ##     ##  ##         ##   ##       ##      
##    ##   ##    ##   ##     ##  ##         ##    ##      ##      
 ######     ##### ##   #######   ########   ##     ##     ##      
*/

$(document).ready(function() {

	/*
	 ######     #######    #######   ##    ##   ####  ########   
	##    ##   ##     ##  ##     ##  ##   ##     ##   ##         
	##         ##     ##  ##     ##  ##  ##      ##   ##         
	##         ##     ##  ##     ##  #####       ##   ######     
	##         ##     ##  ##     ##  ##  ##      ##   ##         
	##    ##   ##     ##  ##     ##  ##   ##     ##   ##         
	 ######     #######    #######   ##    ##   ####  ########   
	*/
	$.cookieBar({
		message: "Nous diffusons des cookies afin d'analyser le trafic sur ce site. Les informations concernant l'utilisation que vous faites de notre site nous sont transmises dans cette optique.",
		acceptText: "J'accepte",
		declineButton: true,
		declineText: 'Je refuse',
		expireDays: 30,
		fixed: true,
		zindex:10000
	});

	if(jQuery.cookieBar('cookies')){
		//Google Analytics or other code here
	}

	FastClick.attach(document.body);

	$('[data-link]').click(function() {
		window.location.href = $(this).attr('data-link');
	}).find('a').click(function(e) {
		e.stopPropagation();
	});

	/**
	 * Test si les Media Queries sont supportées
	 * Ajoute la classe 'mq' au tag HTML si c'est le cas
	 */
	if ( Modernizr.mq('only all') ) {
		jQuery('html').addClass('mq');
	} else {
		jQuery('html').addClass('no-mq');
	}

	/**
	 * Browser detect : masquage de la bannière
	 */
	$('.browserdetect__close').click(function(e) {
		e.preventDefault();
		$('.browserdetect').addClass('browserdetect--hidden');
	});

	/**
	 * MatchHeight [GRID]
	 */
	$(".harmonize").matchHeight();

	$(".mobile").click(function(e) {
			e.preventDefault();
			$('body').addClass('nav-open');
	});

	$(".nav-main__close").click(function(e) {
			e.preventDefault();
			$('body').removeClass('nav-open');
	});

	/*
	 ######     #######   ##    ##   ########      ###      ######    ########   
	##    ##   ##     ##  ###   ##      ##        ## ##    ##    ##      ##      
	##         ##     ##  ####  ##      ##       ##   ##   ##            ##      
	##         ##     ##  ## ## ##      ##      ##     ##  ##            ##      
	##         ##     ##  ##  ####      ##      #########  ##            ##      
	##    ##   ##     ##  ##   ###      ##      ##     ##  ##    ##      ##      
	 ######     #######   ##    ##      ##      ##     ##   ######       ##      
	*/

	$("form").validationEngine({ 
		scroll: false,
		showPrompts: false,
		onFieldFailure: function(_input){
			_input.parent('li').addClass('error');
		},
		onFieldSuccess: function(_input){
			_input.parent('li').removeClass('error');
		}
	});

	$("#fcontact").submit(function(){
		if($("#fcontact").validationEngine('validate')){
			var data = $(this).serialize() + '&act=envoi';
			var url  = $(this).attr('action');
			var div  = $(this).empty().html('<div class="form-return">envoi en cours...</div>');

			$.ajax({
				type    : "POST",
				data    : data,
				url     : url,
				success : function(retour) {
					div.empty().append(retour);
				}
			});
		}
		return false;
	});
});
